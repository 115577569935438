<template lang="html">
  <div tabindex="100" @keydown.up.prevent="onArrowUpPress" @keydown.down.prevent="onArrowDownPress">

    <v-card class="card-size">
      <v-card-title>
        <h2 class="headline mb-0" v-text="title">
        </h2>
      </v-card-title>

      <v-row justify="start" align="center">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field id="usuario-tabela-input-search" v-model="searchQuery" @input="filtrar" append-icon="search"
            :label="$t('label.pesquisar')" single-line clearable hide-details>
          </v-text-field>
        </v-col>
        <div class="mt-1 ml-2" v-if="canAccessCRUD">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon id="usuario-tabela-button-config" class="mx-0" v-on="on" @click="abrirConfiguracao">
                <v-icon>settings</v-icon>
              </v-btn>
            </template>
            <span>{{ $tc('label.configuracao', 1) }}</span>
          </v-tooltip>
        </div>
        <v-col cols="12" sm="6" md="3">
          <v-switch v-model="indSomenteUsuarioMaster" :label="$t('label.exibir_somente_usuarios_representantes_legais')">
          </v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="3" v-if="isAprovadorRepresentanteLegal">
          <v-switch v-model="indMinhasAprovacoesPendentes" label="Minhas Aprovações Pendentes">
          </v-switch>
        </v-col>
      </v-row>

      <v-data-table :headers="headers" :items="registros" :options.sync="pagination" :item-class="onItemClass"
        :server-items-length="totalPage" :no-data-text="$t('label.tabela_sem_conteudo')" :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="podeEditar(item)" @click="edit(item)">
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.editar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="podeVisualizar(item)" @click="ver(item)">
                <v-list-item-action>
                  <v-icon>dehaze</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.visualizar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canAccessCRUD || isUsuarioExternoMaster" @click="redefinirSenha(item)">
                <v-list-item-action>
                  <v-icon>lock</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.redefinir_senha') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="buscaDadosUltimaAlteracao(item)">
                <v-list-item-action>
                  <v-icon>app_registration</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.ultima_alteracao') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="buscaRelatorioLogUsuarioPorId(item)">
                <v-list-item-action>
                  <v-icon>get_app</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.exportar_log') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="podeAprovarAprovarRepresentanteLegal(item.statusFluxo, item.indPossuiAnexos)"
                @click="openModalJustificativaAprovacao(item, 'APROVADO', $t('label.aprovar'))">
                <v-list-item-action>
                  <v-icon>thumb_up</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.aprovar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="podeAprovarAprovarRepresentanteLegal(item.statusFluxo, item.indPossuiAnexos)"
                @click="openModalJustificativaAprovacao(item, 'REPROVADO', $t('label.reprovar'))">
                <v-list-item-action>
                  <v-icon>thumb_down</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.reprovar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="mostrarAcompanhamento(item)" @click="abrirAcompanhamento(item.idUsuario)">
                <v-list-item-action>
                  <v-icon>assignment_turned_in</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.nome_sobrenome="{ item }">
          {{ item.nomeSobrenome }}
        </template>
        <template v-slot:item.nom_usuario="{ item }">
          {{ item.nomUsuario }}
        </template>
        <template v-slot:item.nom_perfil="{ item }">
          {{ item.nomPerfil }}
        </template>
        <template v-slot:item.nom_estrutura_usuario="{ item }" v-if="isUsuarioFuncionario">
          {{ item.nomEstruturaUsuario }}
        </template>
        <template v-slot:item.divisoes="{ item }" >
          {{ getNomesDivisoesSeparadosPorVirgula(item.divisoes) }}
        </template>
        <template v-slot:item.grupofornecedor="{ item }" v-if="!isUsuarioFuncionario">
          {{ montaTexto(item.grupofornecedor) }}
        </template>
        <template v-slot:item.indUsuarioExternoMaster="{ item }">
          <span v-if="isUsuarioFornecedor(item)">{{ item.indUsuarioExternoMaster ?
            $t('label.tipos_fornecedor.representante_legal') : $t('label.aprovador_autorizado') }}</span>
        </template>
        <template v-slot:item.indProcurador="{ item }">
          <span v-if="isUsuarioFornecedor(item)">{{ item.indProcurador ? $t('label.sim') : '' }}</span>
        </template>
        <template v-if="podeVisualizarAnexos || podeCadastrarAnexos" v-slot:item.indPossuiAnexos="{ item }">
          <v-btn v-if="item.indPossuiAnexos" icon @click="abrirModalAnexos(item)">
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.statusFluxo="{ item }">
          <status v-if="item.statusFluxo" :status="item.statusFluxo" :tooltip="item.statusFluxo"></status>
        </template>
      </v-data-table>
      <usuario-anexos-dialog ref="usuarioAnexosDialog" :pode-visualizar-anexos="podeVisualizarAnexos"
        :pode-cadastrar-anexos="podeCadastrarAnexos">
      </usuario-anexos-dialog>
    </v-card>
    <modal-dados-ultima-alteracao-usuario ref="modalDadosUltimaAlteracaoUsuario">
    </modal-dados-ultima-alteracao-usuario>
    <usuario-aprovacao-dialog ref="modalJustificativaAprovacao" :title="titleJustificativaModal"
      :obrigatorio="statusAprovacaoUsuario !== 'APROVADO'" @Afirmativo="aprovarReprovarUsuario">
    </usuario-aprovacao-dialog>
    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'REPRESENTANTE_LEGAL'"
    >
    </acompanhamento>
  </div>
</template>

<script type="text/javascript">
import {
  mapGetters,
} from 'vuex';
import { removeEmptyProperties } from '@/produto/common/functions/helpers';
import exportacao from '@/produto/common/functions/exportacao';
import { countRelatorioLogUsuario } from '@/common/resources/magalu-relatorios'; // eslint-disable-line
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';
import Status from '../../shared-components/Status';
import UsuarioAnexosDialog from './UsuarioAnexosDialog';
import { skipLoading } from '../../common/functions/loading';
import ModalDadosUltimaAlteracaoUsuario from '../../shared-components/ModalDadosUltimaAlteracaoUsuario';
import UsuarioAprovacaoDialog from './UsuarioAprovacaoDialog';

export default {
  components: {
    UsuarioAnexosDialog,
    ModalDadosUltimaAlteracaoUsuario,
    UsuarioAprovacaoDialog,
    Acompanhamento,
    Status,
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      usuarioResources: this.$api.usuario(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      headers: [
        {
          text: this.$tc('label.acoes', 2), sortable: false, align: 'center', value: 'acoes',
        },
        { text: this.$tc('label.tipo', 1), sortable: true, value: 'tipo' },
        { text: this.$t('label.nome'), sortable: true, value: 'nome' },
        { text: this.$t('label.email'), sortable: true, value: 'email' },
        { text: this.$t('label.login'), sortable: true, value: 'nom_usuario' },
        { text: this.$tc('label.perfil', 1), value: 'nom_perfil' },
        { text: this.$tc('label.grupo_fornecedor', 1), value: 'grupofornecedor', sortable: false },

      ],
      workspace: {},
      entidadeWorkspace: 'gestao_usuario',
      pagination: {},
      totalPage: 0,
      esperar: false,
      searchQuery: '',
      registros: [],
      indSomenteUsuarioMaster: false,
      indMinhasAprovacoesPendentes: false,
      bloquearExportacao: false,
      idPerfilAprovadorLegal: null,
      statusAprovacaoUsuario: '',
      titleJustificativaModal: '',
      usuarioAprovar: null,
      statusPassos: {},
    };
  },
  props: {
    title: String,
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar(true);
      },
      deep: true,
    },
    indSomenteUsuarioMaster() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar(false);
      }, 500);
    },
    searchQuery() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.salvarFiltrosWorkspace();
        this.filtrar(false);
      }, 500);
    },
    paginationSize(valor) {
      this.$emit('PAGINACAO_ALTERANDO_TAMANHO_PAGINA', valor);
    },
    indMinhasAprovacoesPendentes() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar(false);
      }, 500);
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'implantacao',
      'indModoVarejo',
    ]),
    ...generateComputed('Usr', [
      'canAccessCRUD',
      'canSearch',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    isContentAvailable() {
      return !!this.tableData && !!this.tableData.length;
    },
    usuarioFilteredList() {
      return this.tableData.map((linha) => {
        const row = { ...linha };
        row.representante = {};
        row.representante.dependencias = this.stringToArray(linha);
        row.superioresImediatosArray = this.parseStringSuperioresImediatos(linha);
        row.canaisArray = this.parseStringCanal(linha);

        return row;
      });
    },
    filteredList() {
      return [];
    },
    isUsuarioExternoMaster() {
      return this.usuarioLogado.indUsuarioExternoMaster;
    },
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    podeVisualizarAnexos() {
      return this.canSearch;
    },
    podeCadastrarAnexos() {
      return this.canAccessCRUD && this.isUsuarioFuncionario;
    },
    cadastroFornecedorNaoMaster() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONFIG_CAD_FORNECEDOR_NAO_MASTER').length;
    },
    isAprovadorRepresentanteLegal() {
      return this.usuarioLogado.idPerfil === parseInt(this.idPerfilAprovadorLegal, 10);
    },
  },
  methods: {
    onItemClass(...args) {
      const [usuario] = args;
      return !usuario.indAtivo ? 'TableRow--disabled' : '';
    },
    buscar(trocaPagina) {
      if (this.searchQuery && !trocaPagina) {
        this.pagination.page = 1;
      }
      const params = {
        filtro: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      if (!this.isUsuarioFuncionario) {
        params.indUsuarioExterno = true;
      }

      if (params.colunaOrdenacao === 'nome') {
        params.colunaOrdenacao = 'u.nome';
      }

      if (params.colunaOrdenacao === 'indPossuiAnexos') {
        params.colunaOrdenacao = 'ind_possui_anexos';
      }

      if (params.colunaOrdenacao === 'indUsuarioExternoMaster') {
        params.colunaOrdenacao = 'aprovador_autorizado';
      }

      if (this.indSomenteUsuarioMaster) {
        params.indSomenteUsuarioMaster = this.indSomenteUsuarioMaster;
      }

      if (this.indMinhasAprovacoesPendentes) {
        params.indMinhasAprovacoesPendentes = this.indMinhasAprovacoesPendentes;
      }

      this.usuarioResources.listar(params).then((response) => {
        this.registros = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    filtrar(trocaPagina) {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar(trocaPagina);
      }, 1000);
    },
    carregarWorkspaceFiltros() {
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          if (response.data !== '') {
            this.workspace = response.data || {};
            this.searchQuery = this.workspace.filtros.searchQuery !== undefined ? this.workspace.filtros.searchQuery : '';
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = {};
      this.workspace.filtros.searchQuery = this.searchQuery;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    montaTexto(item) {
      if (!item) {
        return '';
      }
      const array = item.split('|');
      return (`${array[2]}`) || '';
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    abrirConfiguracao() {
      this.$emit('USUARIOTABELA_CONFIGURACAO');
    },
    filterDatasource() {
      this.showLoading = true;
      this.paginationPage = 1;

      const pagination = {
        size: this.paginationSize,
        page: this.paginationPage,
        filter: this.searchQuery,
      };

      this.$emit('TABELA_FILTRAR_PAGINA', pagination, this.closeLoading);
    },
    edit(row) {
      this.$emit('USUARIOTABELA_EDIT', row);
    },
    ver(row) {
      this.$emit('USUARIOTABELA_VER', row);
    },
    redefinirSenha(row) {
      this.$emit('USUARIOTABELA_RESET_SENHA', row);
    },
    getNomesDivisoesSeparadosPorVirgula(nomeObjeto) {
      if (!nomeObjeto) {
        return '';
      }
      return nomeObjeto.split(';;').join(', ');
    },
    stringToArray(linha) {
      const canaisVendaRepresentanteObj = !linha.canaisVendaRepresentante ? []
        : linha.canaisVendaRepresentante.split(',').map((cat) => {
          const canaisVendaRepresentanteStr = cat.split(':');
          return {
            id: parseInt(canaisVendaRepresentanteStr[0], 10),
            nomExtensao: canaisVendaRepresentanteStr[1],
            indAtivo: canaisVendaRepresentanteStr[2],
          };
        });

      return {
        canaisVenda: canaisVendaRepresentanteObj,
      };
    },
    abrirModalAnexos(usuario) {
      this.$refs.usuarioAnexosDialog.open(usuario);
    },
    isUsuarioFornecedor(usuario) {
      return !!usuario.tipo && usuario.tipo === 'FORNECEDOR';
    },
    isUsuarioFornecedorMaster(usuario) {
      return usuario.indUsuarioExternoMaster;
    },
    podeEditar(usuario) {
      return this.canAccessCRUD && ((this.isUsuarioFuncionario && (!this.isUsuarioFornecedor(usuario)
        || this.isUsuarioFornecedorMaster(usuario)
        || (this.isUsuarioFornecedor(usuario) && this.cadastroFornecedorNaoMaster)))
        || (this.isUsuarioFornecedorMaster(this.usuarioLogado)
          && this.isUsuarioFornecedor(usuario) && !this.isUsuarioFornecedorMaster(usuario) && this.cadastroFornecedorNaoMaster));
    },
    podeVisualizar(usuario) {
      return !(this.canAccessCRUD && ((this.isUsuarioFuncionario && (!this.isUsuarioFornecedor(usuario)
        || this.isUsuarioFornecedorMaster(usuario)
        || (this.isUsuarioFornecedor(usuario) && this.cadastroFornecedorNaoMaster)))
        || (this.isUsuarioFornecedorMaster(this.usuarioLogado)
          && this.isUsuarioFornecedor(usuario) && !this.isUsuarioFornecedorMaster(usuario) && this.cadastroFornecedorNaoMaster)));
    },
    buscaRelatorioLogUsuarioPorId(usuario) {
      const params = {
        id_usuario_selecionado: usuario.idUsuario ? usuario.idUsuario : usuario.id,
      };
      let quantidadeRegistros = 0;

      countRelatorioLogUsuario(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > this.limiteRegistrosExportacao) {
            this.$toast(this.$t('errors.registros.excede_limite_dados', { limite: this.limiteRegistrosExportacao }));
          } else if (quantidadeRegistros > 0) {
            this.requestExportacao(params, 'log_usuario_magalu', 'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    requestExportacao(params, tipoRelatorio, modulo = 'api') {
      const filtroTratado = removeEmptyProperties(params);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
    buscaDadosUltimaAlteracao(item) {
      const dados = {
        idUsuario: item.id,
      };
      setTimeout(() => this.$refs.modalDadosUltimaAlteracaoUsuario.open(dados), 2E2);
    },
    buscarChaveIDPerfilAprovadorLegal() {
      this.configSistemaResource.buscarChave({ chave: 'APROVADOR_USR_FORNE_REP_LEG' })
        .then((resp) => {
          this.idPerfilAprovadorLegal = resp.body;
        });
    },
    podeAprovarAprovarRepresentanteLegal(statusFluxo, indPossuiAnexos) {
      return this.isAprovadorRepresentanteLegal && statusFluxo === 'AGUARDANDO_APROVACAO' && indPossuiAnexos;
    },
    openModalJustificativaAprovacao(item, status, titleModal) {
      this.usuarioAprovar = item;
      this.titleJustificativaModal = titleModal;
      this.statusAprovacaoUsuario = status;
      this.statusAprovadoRepresentanteLegal = status;
      this.$refs.modalJustificativaAprovacao.open();
    },
    aprovarReprovarUsuario(justificativa) {
      const objArmazenamento = {
        ...this.usuarioAprovar,
      };
      objArmazenamento.statusFluxo = this.statusAprovacaoUsuario;
      objArmazenamento.justificativaAprovacao = justificativa;
      this.usuarioResources.aprovarUsuarioMaster({ id: objArmazenamento.id }, objArmazenamento)
        .then(() => {
          this.$router.push({ name: 'usuario' });
          this.filtrar(false);
          this.$toast(this.$t('message.atualizado_com_sucesso'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    mostrarAcompanhamento(item) {
      return item.statusFluxo && item.statusFluxo !== 'EM_CADASTRO' && item.statusFluxo !== 'CANCELADO';
    },
    abrirAcompanhamento(idUsuario) {
      this.usuarioResources.buscarFluxoAprovacao({ idUsuario })
        .then((res) => {
          this.statusPassos = res.data;
          if (this.statusPassos.passos) {
            const passo = this.statusPassos.passos.find((e) => e.statusPasso === 'AGUARDANDO_APROVACAO');
            if (passo) {
              passo.isProximoPasso = true;
            }
          }
          setTimeout(() => this.$refs.modalAcompanhamento.open());
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    this.carregarWorkspaceFiltros();
    this.filtrar(false);
    this.headers.push({ text: this.$tc('label.usuario_ultima_alteracao', 1), value: 'usuarioAlteracao', sortable: false });
    if (this.isUsuarioFuncionario) {
      this.headers.push({ text: this.$tc('label.divisao', 1), value: 'divisoes' });
    }
    this.headers.push({ text: this.$tc('label.tipo_fornecedor', 1), value: 'indUsuarioExternoMaster', sortable: true });
    this.headers.push({ text: this.$tc('label.procurador', 1), value: 'indProcurador', sortable: true });
    this.headers.push({ text: this.$tc('label.anexo', 2), value: 'indPossuiAnexos', sortable: true });
    this.headers.push({
      text: this.$tc('label.status_aprovacao', 1), value: 'statusFluxo', sortable: true, align: 'center',
    });

    this.buscarChaveIDPerfilAprovadorLegal();
  },
};
</script>
